import React, { Component, Fragment } from "react";
import api, { ServerError } from "api";
import { BillModel, Api } from "models";
import HydrometerBills from "components/Pay/HydrometerBills/HydrometerBills";
import { Table, Card, CardBody, CardTitle } from "reactstrap";
import Warning from "../../Warning";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { getGRCurrency } from "utilities";
import { RouteComponentProps } from "react-router";
import withError from "hoc/withError/withError";
import { connect } from "react-redux";
import { ReduxState } from "store";
import PayBtn from "containers/Pay/PayBtn";
import { WithTranslation, withTranslation } from "react-i18next";

interface BillsState {
   hydrometers: { bills: BillModel[]; info: Api.Hydrometer.Info }[];
   loading: boolean;
   error?: Error;
   creating: boolean;
}

class Bills extends Component<RouteComponentProps & { mobile: string } & WithTranslation, BillsState> {
   state: BillsState = {
      hydrometers: [],
      loading: true,
      creating: false
   };

   componentDidMount = () => {
      api.get<Api.User.Bill[]>("/User/Bills")
         .then((res) => {
            if (res.data && res.data.length > 0) this.setState({ hydrometers: res.data });
         })
         .catch((e) => {
            if (e.response) {
               this.setState({ error: new ServerError(e.response.data?.detail) });
            } else this.setState({ error: new Error(e) });
         })
         .finally(() => this.setState({ loading: false }));
   };

   getBillSum = (billTypeFilter?: (billType: number) => boolean) => {
      if (billTypeFilter) {
         return this.state.hydrometers
            .map((h) => h.bills)
            .reduce((prevBills, bill) => prevBills.concat(bill))
            .filter((bill) => billTypeFilter(bill.billType))
            .reduce((sum, bill) => sum + (bill.payAmount || 0), 0);
      } else {
         return this.state.hydrometers
            .map((h) => h.bills)
            .reduce((prevBills, bill) => prevBills.concat(bill))
            .reduce((sum, bill) => sum + (bill.payAmount || 0), 0);
      }
   };

   render() {
      if (this.state.error) throw this.state.error;
      else if (this.state.loading) return this.loadingView();
      else if (this.state.hydrometers.length > 0)
         return (
            <Fragment>
               {this.billsView()}
               {this.sumNPayCard()}
            </Fragment>
         );
      else throw new Warning(this.props.t("messages:no_updated"));
   }

   loadingView = () => (
      <SkeletonTheme color="#fff" highlightColor="#fafafa">
         <Skeleton duration={2} key="last_skeleton" height={287} />
      </SkeletonTheme>
   );

   billsView = () =>
      this.state.hydrometers.map((hydro, index) => (
         <HydrometerBills
            key={`hydrometer_${index}`}
            {...hydro}
            defaultOpen={index === 0}
            onSomeAmountChange={(i, val) => {
               this.setState((prevState) => {
                  try {
                     const hydros: { bills: BillModel[]; info: Api.Hydrometer.Info }[] = JSON.parse(JSON.stringify(prevState.hydrometers));
                     hydros[index].bills[i].payAmount = val;
                     return { hydrometers: hydros };
                  } catch (err) {
                     return { hydrometers: prevState.hydrometers };
                  }
               });
            }}
         />
      ));

   getSums = () => {
      const installments = this.getBillSum((d) => d === 1);
      const bills = this.getBillSum((d) => d === 0);
      if (installments > 0 && bills > 0)
         return {
            [this.props.t("bills:bill_sum")]: getGRCurrency(bills),
            [this.props.t("bills:installment_sum")]: getGRCurrency(installments),
            [this.props.t("bills:sum")]: getGRCurrency(this.getBillSum())
         };
      else
         return {
            [this.props.t("bills:pay_sum")]: getGRCurrency(this.getBillSum())
         };
   };

   sumsTable = () => {
      const sums: any = this.getSums();
      return (
         <>
            <div className="d-sm-none">
               <hr className="my-3" />
               {Object.keys(sums).map((k, i) => (
                  <div key={"sums_" + i} className="mb-3">
                     <p className="text-right font-weight-bold mt-2 mb-0">{k}</p>
                     <p className="text-right mt-0 mb-0">{sums[k]}</p>
                  </div>
               ))}
            </div>
            <div className="d-none d-sm-block">
               <Table>
                  <tbody>
                     {Object.keys(sums).map((k, i) => (
                        <tr key={"sums_" + i}>
                           <th scope="row">{k}</th>
                           <td align="right"> {sums[k]}</td>
                        </tr>
                     ))}
                  </tbody>
               </Table>
            </div>
         </>
      );
   };

   sumNPayCard = () => (
      <Card className="mt-4 shadow-sm border-0">
         <CardBody>
            <CardTitle className="lead">
               <strong>{this.props.t("bills:payment_confirmation")}</strong>
            </CardTitle>
            {this.sumsTable()}
            <PayBtn
               phone={this.props.mobile}
               className="float-right mt-1"
               color="primary"
               bills={this.state.hydrometers.reduce<BillModel[]>((allBills, { bills }) => allBills.concat(bills), [])}
            >
               {this.props.t("bills:pay")}
            </PayBtn>
         </CardBody>
      </Card>
   );
}

export default connect<{ mobile: string }, unknown, unknown, ReduxState>((state) => ({
   mobile: (state.userInfo?.phoneNumberDialCode || "") + (state.userInfo?.phoneNumber || "")
}))(withTranslation(["bills", "messages"])(withError(Bills)));
