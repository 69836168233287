import React, {FC, useEffect, useState} from "react";
import {Api} from "models";
import {Alert, Button, Col, Form, Modal, ModalBody, ModalFooter, Row} from "reactstrap";
import withError from "hoc/withError/withError";
import {useTranslation} from "react-i18next";
import api from "../../api";
import DecisionSelector from "../../components/Arrangements/DecisionSelector";
import TermSelector from "../../components/Arrangements/TermSelector";
import Calculation from "../../components/Arrangements/Calculation";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";
import {getGRCurrency} from "../../utilities";

interface AddArrangementProps {
    cccydrmtr: number;
    trdr: number;
    debt: number;
    identifier: string;
    meter: string;
}

const AddArrangement: FC<AddArrangementProps> = ({cccydrmtr, trdr, debt, meter, identifier}) => {
    const [t] = useTranslation(["messages", "errors", "arrangements"]);
    const [errorMsg, setErrorMsg] = useState("");
    const [loadingDecisions, setLoadingDecisions] = useState(false);
    const [decisions, setDecisions] = useState<Api.Arrangements.Decision[]>([])
    const [selectedDecisionId, setSelectedDecisionId] = useState<string>();
    const [loadingTerms, setLoadingTerms] = useState(false);
    const [terms, setTerms] = useState<Api.Arrangements.Term[]>();
    const [selectedInstallmentsCount, setSelectedInstallmentsCount] = useState<string>();
    const [loadingCalculation, setLoadingCalculation] = useState(false);
    const [calculation, setCalculation] = useState<Api.Arrangements.Calculation>();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [proceedError, setProceedError] = useState<string>();
    const [proceeding, setProceeding] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        (async () => {
            try {
                setLoadingDecisions(true);
                const res = await api.get<Api.Arrangements.Decision[]>("/Arrangements/GetDecisions");
                setDecisions(res.data);
                setErrorMsg("");
            } catch (error) {
                setErrorMsg(error.response.data.detail || error || "hydrometer:error");
            } finally {
                setLoadingDecisions(false);
            }
        })();
    }, []);

    useEffect(() => {
        if (!selectedDecisionId) {
            setSelectedInstallmentsCount(undefined);
            setTerms(undefined);
        } else
            (async () => {
                try {
                    setLoadingTerms(true);
                    const res = await api.post<Api.Arrangements.Term[]>("/Arrangements/GetTerms", {data: selectedDecisionId});
                    setTerms(res.data);
                    setLoadingTerms(false);
                    setErrorMsg("");
                } catch (error) {
                    setErrorMsg(error.response.data.detail || error || "hydrometer:error");
                } finally {
                    setLoadingTerms(false);
                }
            })();
    }, [selectedDecisionId]);

    useEffect(() => {
        if (!selectedInstallmentsCount)
            setCalculation(undefined);
        else
            (async () => {
                try {
                    setLoadingCalculation(true);
                    const res = await api.post<Api.Arrangements.Calculation>(
                        "/Arrangements/Calculate",
                        {
                            data: {
                                decisionId: selectedDecisionId,
                                installmentsCount: selectedInstallmentsCount,
                                trdr,
                                cccydrmtr
                            }
                        });
                    setCalculation(res.data);
                    setLoadingCalculation(false);
                    setErrorMsg("");
                } catch (error) {
                    setErrorMsg(error.response.data.detail || error || "hydrometer:error");
                } finally {
                    setLoadingCalculation(false);
                }
            })();
    }, [selectedInstallmentsCount]);

    const onProceed = async () => {
        try {
            setProceeding(true);
            setProceedError(undefined);
            const res = await api.post("/Arrangements/Add", {
                data: {
                    termId: calculation?.termId,
                    cccydrmtr,
                    trdr,
                    installmentsCount: selectedInstallmentsCount,
                    decisionId: selectedDecisionId
                }
            });
            history.push(`/MyBills?msg=${encodeURI(t("arrangements:success_msg", {
                eCode: res.data.eCode,
                meter,
                installmentAmount: calculation?.installmentAmount && getGRCurrency(calculation.installmentAmount)
            }))}`);
        } catch (e) {
            setProceedError(e.response?.data?.detail || "generic_error");
        } finally {
            setProceeding(false);
        }
    };

    const errView = <Alert color="danger">{t(errorMsg)}</Alert>;

    const defaultView = (
        <>
            <Form>
                <hr/>
                <Row className="my-1">
                    <Col>
                        <h5 className="text-center">
                            <span className="font-weight-lighter">{t("hydrometer:hydrometer")}</span>{" "}
                            <strong>{meter}</strong>
                        </h5>
                        <p className="text-center font-weight-light text-secondary">
                            {identifier}
                        </p>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col xs={12} md={6}>
                        <DecisionSelector onChange={setSelectedDecisionId}
                                          value={selectedDecisionId}
                                          decisions={decisions}
                                          loading={loadingDecisions}/>
                    </Col>
                    <Col xs={12} md={6}>
                        <TermSelector
                            onChange={setSelectedInstallmentsCount}
                            value={selectedInstallmentsCount}
                            terms={terms}
                            loading={loadingTerms}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Calculation value={calculation} debt={debt} loading={loadingCalculation}/>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={12} className="d-flex justify-content-end">
                        <Button color="primary" disabled={!selectedInstallmentsCount || !selectedDecisionId || calculation?.isRejected}
                                onClick={() => setIsModalOpen(true)}>{t("arrangements:submit")}</Button>
                    </Col>
                </Row>
            </Form>

            <Modal centered size="lg" isOpen={isModalOpen} toggle={() => {
                if (!proceeding) {
                    setIsModalOpen(false);
                    setProceedError(undefined);
                }
            }}>
                <ModalBody>
                    {t("arrangements:submit_confirmation_msg", {installmentAmount: calculation?.installmentAmount && getGRCurrency(calculation.installmentAmount)})}
                </ModalBody>
                <ModalFooter>
                    {proceedError && (
                        <Row>
                            <Col>
                                <div className="text-danger">{t(proceedError)}</div>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col>
                            <Button disabled={proceeding}
                                    onClick={() => {
                                        if (!proceeding) {
                                            setIsModalOpen(false);
                                            setProceedError(undefined);
                                        }
                                    }}>{t("arrangements:cancel")}</Button>
                            <Button className="ml-2" color="primary" disabled={proceeding}
                                    onClick={onProceed}>{t("arrangements:proceed")}</Button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </>
    );

    return errorMsg ? errView : defaultView;
};

export default withError(AddArrangement);
